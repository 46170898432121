import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "../../components/Button";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import TitleBar from "../../components/TitleBar";
import Headline from "../../components/Headline";
import Ctas from "../../components/Ctas";
import TableOfContents from "../../components/TableOfContents";
import TableOfContentsItem from "../../components/TableOfContentsItem";
import SupportLink from "../../components/SupportLink";
import Feature from "../../components/Feature";
import WorksWith from "../../components/WorksWith";
import Issue from "../../components/Issue";
import TrySqueak from "../../components/TrySqueak";
import Callout from "../../components/Callout";
import SqueakSnippet from "../../components/SqueakSnippet";
import { Github, QnaJs } from "../../components/Icons";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Screenshot from '../../images/qnajs/screenshot.png'

const Step = ({ step, subtitle, image }) => {
  return (
    <li className="pb-4 last:pb-0 md:pb-8 m-0">
      <h5 className="opacity-50 m-0">Step {step}</h5>
      <h4 className="text-2xl font-bold text-black mb-5">
        {subtitle}
      </h4>
      <div className="max-w-prose -mx-3">{image}</div>
    </li>
  );
};

const ToolkitQna = () => {
  return (
    <Layout title="Threaded discussions on any page of your docs or website">
      <TitleBar title="Q&amp;A.js" toolkit icon={<QnaJs />}>
        <div className="hidden sm:flex">
          <Button url="https://squeak.cloud/signup">
              Try Squeak! Cloud
          </Button>
        </div>
      </TitleBar>
      
      <article className="">
        <Section id="overview">
          <Headline
            h1="Add <span className='text-red'>threaded discussions</span> to any page of your docs or website"
            subtitle="Q&amp;A.js is an embeddable, open source widget for people to ask or answer questions. It's like Stack Overflow in your docs!"
          />

          <Ctas className="pb-12">
            <Button url="https://squeak.cloud/signup">Try Squeak! Cloud</Button>

            <div className="relative">
              <Button
                url="#demo"
                type="secondary"
                className="w-full"
              >
                Demo
              </Button>
              <TrySqueak className="absolute top-[140%] sm:top-[105%] pt-1 left-[35%] sm:left-[75%] rotate-3 sm:-rotate-6" />
            </div>
          </Ctas>
        </Section>
      
        <TableOfContents>
            <TableOfContentsItem name="Screenshot" url="#screenshots" />
            <TableOfContentsItem name="Features" url="#features" />
            <TableOfContentsItem name="How it works" url="#how-it-works" />
            <TableOfContentsItem name="Demo" url="#demo" />
            <SupportLink name="Questions?" url="/questions/qna.js" />
        </TableOfContents>

        <Section id="screenshots">

          <Zoom>
            <img src={Screenshot} className="max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl -mx-6 w-[calc(100%+3rem)] xl:-mx-10 xl:w-[calc(100%+5rem)] 2xl:-mx-12 2xl:w-[calc(100%+6rem)] transition-all duration-300" alt="Squeak! Q&A screenshot"/>
          </Zoom>

          <div className="text-center">
            <p className="text-base inline-flex flex-col md:flex-row items-center text-opacity-75 bg-white rounded-full px-4 py-1">
              <span className="text-2xl mr-1">👆</span> Here's what Squeak! looks
              like on{" "}
              <a
                href="https://posthog.com/docs"
                target="_blank"
                rel="noreferrer"
                className="ml-1"
              >
                PostHog's docs
              </a>
              .
            </p>
          </div>
        </Section>

        <Section id="features">
            <h3>Features</h3>

            <h4 className="text-black text-opacity-60 text-lg pb-2">Available now</h4>
            <ul className="text-base mb-8">
              <Feature name="A widget appears wherever it's installed (unique to that specific URL)" />
              <Feature name="Questions previously asked on that page will render" />
              <Feature name="Option to moderate new posts and replies before publishing" />
              <Feature name="Ability to show specific threads on multiple pages" />
              <Feature name="Close threads" />
              <Feature name="Mark an answer as the accepted solution" />
              <Feature name="Slack notifications for new questions" />
              <Feature name="Webhook support" />
              <Feature name="User emails (via Mailgun)" />
            </ul>

            <h4 className="text-black text-opacity-60 text-lg pb-2">Toolkit integrations</h4>
            <ul className="text-base mb-8">
              <WorksWith toolkitItem="Slack Thread Importer" to="Import community Slack threads and display on specific webpages"  url="/toolkit/import-slack-threads" />
              <WorksWith toolkitItem="Discussion Warehouse" to="Manage visibility of questions and moderate responses"  url="/toolkit/discussion-warehouse" />
            </ul>

            <h4 className="text-black text-opacity-60 text-lg pb-2">On the roadmap</h4>
            <ul className="text-base mb-8">
              <Issue name='"I have this question, too" button' url="https://github.com/PostHog/squeak/issues/56" />
              <Issue name="Vote on best answer" url="https://github.com/PostHog/squeak/issues/55" />
              <Issue name="Thread sorting" url="https://github.com/PostHog/squeak/issues/58" />
              <Issue name="Slack notifications for thread replies" url="https://github.com/PostHog/squeak/issues/137" />
              <Issue name="A widget with a feed of all recently asked (unanswered) questions" url="https://github.com/PostHog/squeak/issues/118" />
            </ul>

            <Callout linkLabel="Browse full roadmap" linkURL="https://github.com/orgs/PostHog/projects/40">
              <p><strong>Q&amp;A.js is in beta (and free)</strong> until the above list is completed.</p>
            </Callout>

            <h4 className="text-black text-opacity-60 text-lg pb-2">Future ideas</h4>
            <ul className="text-base mb-8">
              <Issue name="A permalink for every question" url="https://github.com/PostHog/squeak/issues/119" />
              <Issue name="Notify specific moderators in Slack by URL rules" url="https://github.com/PostHog/squeak/issues/148" />
              <Issue name="Reply to question directly from Slack" url="https://github.com/PostHog/squeak/issues/95" />
              <Issue name="Allow questions from anonymous users (no login required)" url="https://github.com/PostHog/squeak/issues/51" />
            </ul>

        </Section>

        <Section id="how-it-works">
            <h3>How it works</h3>
              <p className="">Here's what happens when a user asks a question on a page of your website or docs.</p>
              <ol className="pt-8">
                <Step
                  step={1}
                  subtitle="A user posts a question on your website or docs"
                  image={
                    <StaticImage
                      quality={100}
                      src="../../images/qnajs/step-1.png"
                      alt="A user posts a question on your website or docs"
                    />
                  }
                />
                <Step
                  step={2}
                  subtitle="Moderators get notified in Slack"
                  image={
                    <StaticImage
                      quality={100}
                      src="../../images/qnajs/step-2.png"
                      alt="Moderators get notified in Slack"
                    />
                  }
                />
                <Step
                  step={3}
                  subtitle="Moderators (and your community) can reply"
                  image={
                    <StaticImage
                      quality={100}
                      src="../../images/qnajs/step-3.png"
                      alt="Moderators (and your community) can reply"
                    />
                  }
                />
                <Step
                  step={4}
                  subtitle="Response is sent to the question’s author"
                  image={
                    <StaticImage
                      quality={100}
                      src="../../images/qnajs/step-4.png"
                      alt="Response is sent to the question’s author"
                    />
                  }
                />
              </ol>
        </Section>

        <Section id="demo">
          <h3>Demo</h3>
          <SqueakSnippet />
        </Section>

        <Section id="try">
          <h3>Try Q&amp;A.js</h3>

          <Ctas>

            <Button url="https://squeak.cloud/signup">Try Squeak! Cloud</Button>

            <div className="relative">
              <Button
                url="https://github.com/posthog/squeak"
                type="secondary"
                icon={<Github />}
              >
                Browse on GitHub
              </Button>
            </div>

          </Ctas>
        </Section>
      </article>
    </Layout>
  );
};

export default ToolkitQna;
